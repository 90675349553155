<template>
  <div
      class="map container w-full px-2 my-4"
  >
    <div class="mb-4 flex flex-row items-center justify-center">
      <h1 class="px-2 my-0 flex items-center font-sans font-bold break-normal text-indigo-500 text-xl md:text-2xl">
        Road Sections: Map
      </h1>
      <button
          class="rounded w-52 ml-6 p-2 bg-blue-900 text-white"
          @click="onDownloadVisible"
      >DOWNLOAD SECTIONS IN VISIBLE AREA
      </button>
    </div>
    <div id="map" ref="map"></div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {GET_ENTRIES} from "../store/operations";
import L from "leaflet";
import {downloadZippedList} from "@/lib/downloadZippedList";
import {getURI} from "@/lib/getURI";

export default {
  name: "Map",
  setup() {
    const store = useStore()
    store.dispatch(GET_ENTRIES)
    const rows = computed(() => Object.values(store.state.entries))
    let rendered = false;
    const map = ref(null)
    const leafletMap = ref(null)

    const bindPopup = l => {
      let writable = false;

      const props = JSON.parse(JSON.stringify(l.toGeoJSON().properties))
      let table = ''
      let info = ''
      let uri = props.uri

      let properties = {}

      // Steer clear of XSS
      for (let k in props) {
        let e = escape(k);
        // users don't want to see "[object Object]"
        if (typeof props[k] === 'object') {
          properties[e] = JSON.stringify(props[k]);//escape(JSON.stringify(props[k]));
        } else {
          properties[e] = props[k]//escape(props[k]);
        }
      }

      if (!properties) return;

      if (!Object.keys(properties).length) properties = {'': ''};

      for (let key in properties) {
        table += `
<tr>
  <th class="w-24">
    <input
        type="text"
        class="w-full px-2 h-6"
        value="${ key }"${ (!writable ? ' readonly' : '')}
    />
  </th>
  <td>
    <input class="w-72 px-2 h-6" type="text" value="${ properties[key] }"${ (!writable ? ' readonly' : '') } />
  </td>
</tr>`
      }

      if (l.feature && l.feature.geometry) {
        info += '<table class="metadata">';
        if (l.feature.geometry.type === 'LineString') {
          let total = d3.pairs(l.feature.geometry.coordinates).reduce(function (total, pair) {
            return total + L.latLng(pair[0][1], pair[0][0])
                .distanceTo(L.latLng(pair[1][1], pair[1][0]));
          }, 0);
          info += `
<tr>
  <td>Meters</td>
  <td>${total.toFixed(2)}</td>
</tr>
<tr>
  <td>Kilometers</td>
  <td>${(total / 1000).toFixed(2)}</td>
</tr>
<tr>
  <td>Feet</td>
  <td>${(total / 0.3048).toFixed(2)}</td>
</tr>
<tr>
  <td>Yards</td>
  <td>${(total / 0.9144).toFixed(2)}</td>
</tr>
<tr>
  <td>Miles</td><td>${(total / 1609.34).toFixed(2)}</td>
</tr>`
        } else if (l.feature.geometry.type === 'Point') {
          info += '<tr><td>Latitude </td><td>' + l.feature.geometry.coordinates[1].toFixed(4) + '</td></tr>' +
              '<tr><td>Longitude</td><td>' + l.feature.geometry.coordinates[0].toFixed(4) + '</td></tr>';
        }
        info += '</table>';
      }

      let tabs = `
<div class="pad1 clearfix col12">
  <div class="tab col12">
    <input class="hide" type="radio" id="properties" name="tab-group" checked="true">
    <label class="w-1/2 keyline-top keyline-right tab-toggle pad0 pin-bottomleft z10 center" for="properties">Properties</label>
    <div class="space-bottom1 col12 content">
      <table class="space-bottom0 marker-properties">${table}</table>
      <a href="${uri}" target="_blank">
        <button class="rounded w-full mt-4 h-9 bg-blue-900 text-white">
            VIEW ENTRY
        </button>
      </a>
    </div>
  </div>
  <div class="space-bottom2 tab col12">
    <input class="hide" type="radio" id="info" name="tab-group">
    <label class="w-1/2 keyline-top tab-toggle pad0 pin-bottomright z10 center" for="info">Info</label>
    <div class="space-bottom1 col12 content">
      <div class="marker-info">${info}</div>
    </div>
  </div>
</div>
`

      let content = tabs +
          (writable ? `
<div class="clearfix pad1 keyline-top">
  <div class="pill col6">
    <button class="save col6 major">Save</button>
    <button class="minor col6 cancel">Cancel</button>
  </div>
  <button class="col6 text-right pad0 delete-invert">
    <span class="icon-remove-sign"></span>
    Delete feature
  </button>
</div>
` : '')

      l.bindPopup(L.popup({
        closeButton: false,
        maxWidth: 450,
        maxHeight: 400,
        autoPanPadding: [5, 45],
        className: 'geojsonio-feature'
      }, l).setContent(content));

    }

    const renderMap = value => {
      if (!rendered) {

        rendered = true
        leafletMap.value = L
            .map("map")
            .setView([47.505, 3.09], 6)

        const osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })

        osm.addTo(leafletMap.value)


        L.geoJSON(value, {
          style: function (feature) {
            return {
              color: feature.properties.stroke ?? '#555555',
              weight: feature.properties['stroke-width'] ?? 2,
              opacity: feature.properties['stroke-opacity'] ?? 1,
            }
          },
          onEachFeature: (feature, layer) =>
              layer.on('add', () => layer._path.setAttribute('id', feature.uuid))
        })
            .eachLayer(l => {
              bindPopup(l)
              l.addTo(leafletMap.value)
            })

      }
    }

    onMounted(() => {
      watch(rows, (value) => {
        if (value?.length) renderMap(value)
      }, {immediate: true})
    })

    const onDownloadVisible = () => {
      const filter = {
        bounds: leafletMap.value.getBounds(),
        uris: [...document.querySelectorAll('#map path:not([d="M0 0"])[fill="none"]')]
            .map(path => getURI(path.getAttribute('id'))),
        uuids: [...document.querySelectorAll('#map path:not([d="M0 0"])[fill="none"]')]
            .map(path => path.getAttribute('id')),

      }
      const features = filter.uuids.map(uuid => store.state.entries[uuid])
      downloadZippedList('map_frame', features, filter)
    }

    return {
      onDownloadVisible,
      map,
    }
  },


}
</script>

<style>
#map {
  height: 90vh;
  width: 100%;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

body#geojsonio-body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #222;
  -webkit-font-smoothing: antialiased;
}

:focus {
  outline: 0;
}

a {
  color: #2980b9;
  text-decoration: none;
}

a:hover {
  color: #199CF4;
}

p {
  padding: 10px 0;
  line-height: 200%;
}

small {
  font-size: 11px;
}

.pad0 {
  padding: 5px;
}

.pad1 {
  padding: 10px;
}

.space-bottom0 {
  margin-bottom: 5px;
}

.space-bottom1 {
  margin-bottom: 10px;
}

.space-bottom2 {
  margin-bottom: 20px;
}

.z10 {
  z-index: 10;
}

.pin-bottomleft,
.pin-bottomright {
  position: absolute;
}

.pin-bottomright {
  bottom: 0;
  right: 0;
}

.pin-bottomleft {
  bottom: 0;
  left: 0;
}

.keyline-top {
  border-top: 1px solid #ccc;
}

.keyline-right {
  border-right: 1px solid #ccc;
}

.clearfix:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  display: inline-block;
}

.hide {
  display: none;
}

.center {
  text-align: center;
}

.text-right {
  text-align: right;
}

table tr td input,
table tr th input {
  border: none;
}

table thead {
  background: #f7f7f7;
  border: 1px solid #ccc;
}

table td {
  border: 1px solid #ccc;
}

table thead tr th {
  font-weight: bold;
}

table thead tr th,
table tr th input,
table tbody tr td input {
  text-align: left;
  font: inherit;
  /*padding: 5px;*/
  /*min-width: 150px;*/
  box-sizing: border-box;
  margin: 0;
}

input[type=text] {
  font: inherit;
}

.loading:before {
  content: '';
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.25);
  z-index: 10;
}

.loading:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0px 0px -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.25;
}

.marker-properties,
.metadata {
  border-collapse: collapse;
  font-size: 11px;
  width: max-content;
  overflow: auto;
  border-bottom: 1px solid #ccc;

  /*max-height: 189px;*/
}

.marker-properties {
  display: block;
}

.metadata {
  display: table;
}

.marker-properties th {
  width: 33.3333%;
  min-width: 100px;
  white-space: nowrap;
  border: 1px solid #ccc;
}

.marker-properties.display td,
.marker-properties.display th {
  padding: 5px 10px;
}

.marker-properties tr:last-child td,
.marker-properties tr:last-child th {
  border-bottom: none;
}

.marker-properties tr:nth-child(even) th,
.marker-properties tr:nth-child(even) td,
.metadata tr:nth-child(even) td {
  background-color: #f7f7f7;
}

.geojsonio-feature .leaflet-popup-content-wrapper {
  float: left;
  width: 100%;
  padding: 0;
}

.geojsonio-feature .leaflet-popup-content {
  float: left;
  padding: 0 !important;
  width: 100%;
}

.geojsonio-feature .leaflet-popup-tip-container {
  float: left;
  margin-left: 50%;
  right: 10px;
}

datalist {
  overflow: auto;
  /*height: 150px;*/
}

.tab .tab-toggle {
  background: #eee;
  cursor: pointer;
}

.tab .tab-toggle:hover {
  background: #f7f7f7;
}

.tab .hide {
  display: none;
}

.tab .content {
  display: none;
  background: white;
  overflow: auto;
}

.tab [type=radio]:checked ~ label {
  background: white;
  border-top-width: 0;
  z-index: 2;
}

.tab [type=radio]:checked ~ label ~ .content {
  z-index: 1;
  display: block;
}

.add-row-button {
  color: #2980b9;
}

.add-row-button:hover {
  cursor: pointer;
  color: #199CF4;
}


.pill button:first-child {
  border-radius: 3px 0 0 3px;
}

.pill button:last-child {
  border-radius: 0 3px 3px 0;
}

</style>
